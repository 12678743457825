interface ConfigProps {
  [key: string]: { color: string; bg: string }
}

const networks: ConfigProps = {
  vk: {
    color: '#fff',
    bg: '#1E78FD'
  },
  'yandex-direct': {
    color: '#000',
    bg: '#DFDFDF'
  },
  'google-ads': {
    color: '#fff',
    bg: '#3FBD13'
  },
  'vk-reklama': {
    color: '#fff',
    bg: '#1E78FD'
  },
  'tik-tok': {
    color: '#fff',
    bg: '#000000'
  },
  'my-target': {
    color: '#fff',
    bg: '#FC3F1D'
  },
  'vk-ads': {
    color: '#fff',
    bg: '#1E78FD'
  },
  'telegram-ads': {
    color: '#fff',
    bg: '#28A8E9'
  },
  directadvert: {
    color: '#fff',
    bg: '#2A7FE0'
  },
  'yandex-business': {
    color: '#fff',
    bg: '#323555'
  },
  'traffic-junky': {
    color: '#fff',
    bg: '#20A5BD'
  },
  taboola: {
    color: '#fff',
    bg: '#18609F'
  },
  yahoo: {
    color: '#fff',
    bg: '#6001D2'
  },
  appnext: {
    color: '#000',
    bg: '#eef2f9'
  },
  avito: {
    color: '#000',
    bg: '#eef2f9'
  },
  'mi-ads': {
    color: '#000',
    bg: '#FFEDDE'
  },
  'petal-ads': {
    color: '#000',
    bg: '#EBEBEB'
  },
  'bigo-ads': {
    color: '#1E2F4B',
    bg: '#EEF2F9'
  },
  'ozon-performance': {
    color: '#fff',
    bg: '#005BFF'
  },
  'apple-search-ads': {
    color: '#1E2F4B',
    bg: '#EBEBEB'
  },
  snapchat: {
    color: '#1E2F4B',
    bg: '#FEF101'
  },
  'unity-ads': {
    color: '#fff',
    bg: '#141414'
  },
  'yandex-promopages': {
    color: '#fff',
    bg: '#000'
  },
  'yandex-geoadv': {
    color: '#000',
    bg: '#EEF2F9'
  },
  'microsoft-advertising': {
    color: '#1E2F4B',
    bg: '#E6E6E6'
  },
  wowblogger: {
    color: '#1E2F4B',
    bg: '#EFF9D9'
  },
  getuniq: {
    color: '#000',
    bg: '#DFDFDF'
  }
}
export default networks
